import { useRef, useState, useEffect,useLayoutEffect, useContext, useCallback } from 'react'
import { createUseStyles } from 'react-jss'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import { Link, useLocation, useHistory,useParams } from 'react-router-dom'
import CountUp from 'react-countup'
import { Context as GeneralContext } from '@/context'
import { Context as AudioContext } from '@/context/audio'
import Meta from '@/components/Meta'
import RevealText from '@/components/RevealText'
import RevealFadeIn from '@/components/RevealFadeIn'
import LocomotiveScroll from '@/components/LocomotiveScroll'
import usePagePrecache from '@/hooks/usePagePrecache'
import usePrevious from '@/hooks/usePrevious'
import * as routerActions from '@/actions/lite-fakerouter'
import * as experienceActions from '@/actions/experience'
import * as liteExperienceActions from '@/actions/lite-experience'
import * as questionsActions from "@/actions/lite-questions";
import * as contentActions from '@/actions/content'
import useStore from '@/base/zustand'
import { convertRichText } from '@/utils/strings'
import style from './style'
import { API_BASE_URL } from '@/constants'

const useStyles = createUseStyles(style)

const LiteBegin = () => {
  const { setPageAnimationReady, pageAnimationReady, headerHeight } = useContext(GeneralContext)
  const { setAudioActive, setAudioControlsVisible, setFirstTimeAudio, isFirstTimeAudio } = useContext(AudioContext)
  const { setFirstTimeAnimation, setScales, setActive } = useStore()
  const [isDataFetched, setDataFetched] = useState(false)
  const [introductionScore, setIntroductionScore] = useState(0)
  const [introductionVariation, setIntroductionVariation] = useState(0)
  const [eventName, setEventName] = useState('')
  const [isPageContentFetched,setIsPageContentFetched] = useState(false)
  const $root = useRef()
  const slug = 'lite-landing-default'

  const location = useLocation()
  const history = useHistory()
  const { eventId } = useParams();
  /*------------------------------
  Redux Store
  ------------------------------*/
  const { currentLanguage,page, fakeLocation, events } = useSelector((state) => ({
    content:state.content,
    page: state.content.['lite-landing-default'] || {},
    fakeLocation: state.fakerouter.location,
    events: state.liteEvent.liteEvents,
    currentLanguage:state.locale.currentLanguage
  }), shallowEqual)

  const classes = useStyles({ headerHeight })

  /*------------------------------
  Redux Actions
  ------------------------------*/
  const dispatch = useDispatch()
  const moveForward = useCallback(() => dispatch(routerActions.moveForward()), [dispatch])
  const changeLocation = useCallback((location) => dispatch(routerActions.changeLocation(location)), [dispatch])
  const setStartingExperience = useCallback((bool) => dispatch(liteExperienceActions.setStartingExperience(bool)), [dispatch])
  const setEndedExperience = useCallback((bool) => dispatch(liteExperienceActions.setEndedExperience(bool)), [dispatch])
  const fetchContent = useCallback((slug) => dispatch(contentActions.fetchContent(slug,'liteLandingPage')), [dispatch])
  const fetchLandingContent = useCallback((id,slug) => dispatch(contentActions.fetchEventContent(id,slug,'liteLandingPage')), [dispatch])
  const resetExperience = useCallback(() => dispatch(liteExperienceActions.resetExperience()), [dispatch])
  const setEvent = useCallback((id, name) => dispatch(liteExperienceActions.setLiteEvent(id, name)), [dispatch])
  const fetchQuestions = useCallback(
    () => dispatch(questionsActions.fetchQuestions()),
    [dispatch]
  );
  const fetchEventLocation = useCallback((coord, city) => {
    dispatch(experienceActions.fetchEventLocation(coord, city))
  }, [dispatch])



  /*------------------------------
  Fetch Page Data
  ------------------------------*/
  useEffect(() => {
    fetchQuestions();
  }, [])

  useEffect(() => {
    if(events.length> 0 ){
      const event = events.map(async (event)=>{
        if(event.sys.id === eventId){
          setEventName(event.fields.title);
          setEvent(event.sys.id,event.fields.title)
          if(event.fields.where) fetchEventLocation(event.fields.where, event.fields.city);
          await fetchLandingContent(event.fields.landingPage.sys.id,slug);
          setIsPageContentFetched(true);
        }
      });
    }
  }, [events])

  useEffect(() => {
    (async()=>{
      if (!eventId) {
        await fetchContent(slug)
        setIsPageContentFetched(true);
      }
    })() 
  }, [])

  const prevLang = usePrevious(currentLanguage)
  useEffect(() => {
    if(prevLang && prevLang !== currentLanguage) { 
      if(eventId){
        fetchLandingContent(events.find((e)=>e.sys.id == eventId)?.fields.landingPage.sys.id,slug);
        return;
      }
      fetchContent(slug);
    }
  }, [currentLanguage])

  useLayoutEffect(() => {
    resetExperience()
  }, [])


  /*------------------------------
  Check data Fetched
  ------------------------------*/
  useEffect(() => {
    if (isPageContentFetched && Object.keys(page).length > 0) setDataFetched(true)
  }, [page,isPageContentFetched])

  useEffect(() => {
    if (location.hash === '#start-experience') {
      handleClickNext()
    }

    if (location.hash === '#prize') {
      localStorage.setItem('checkPrizeBox', true)
      handleClickNext()
    }
  }, [load])

  /*------------------------------
  Preload
  ------------------------------*/
  const [load] = usePagePrecache({
    init: isDataFetched,
    sources: [],
    callback: () => setPageAnimationReady(true),
  })

  /*------------------------------
  Render Helmet
  ------------------------------*/
  const renderHelmet = () => {
    return load && <Meta />
  }

  const handleClickNext = () => {
    // RESET
    setFirstTimeAnimation(true)
    setScales([0, 0, 0, 0, 0, 0, 0, 0, 0, 0])
    setActive(-1)
    //

    setStartingExperience(false)
    setEndedExperience(false)
    setAudioControlsVisible(true)
    if (isFirstTimeAudio) {
      setFirstTimeAudio(false)
      setAudioActive(true)
    }
    if(!!eventName){
      changeLocation('lite-questions')
    }else{
      changeLocation('lite-choose-event')
    }
  }

  /*------------------------------
  Render Hero
  ------------------------------*/
  const renderHero = () => {
    return (
      <div className={classes.hero}>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', minHeight: '100vh' }}>
          <div className={classes.wrapper}>
            <div className={classes.wrapperInside}>
              <RevealText
                tag="h1"
                key={`heading`}
                type="lines"
                value={convertRichText(page.heading)}
                visible={pageAnimationReady}
                lineHeight={1.2}
                delay={0}
                className={classes.beginTitle}
              />
              <RevealText
                tag="div"
                type="lines"
                value={convertRichText(page.bodyCopy)}
                visible={pageAnimationReady}
                lineHeight={1.4}
                delay={1.3}
                className={classes.beginText}
              />
              <RevealFadeIn
                delay={2}
                visible={pageAnimationReady}
              >
                <div className={classes.introButtonWrapper}>
                  <button
                    className={classes.btn}
                    onClick={handleClickNext}
                  >
                    <span>{page.button}</span>
                  </button>
                  <div className={classes.buttonSeparator} />
                  <div className={classes.firstTimeText}>
                    <RevealText
                      tag="div"
                      type="lines"
                      value={convertRichText(page.subCopy)}
                      visible={pageAnimationReady}
                      lineHeight={1.2}
                      delay={0}
                      className={classes.findOutButton}
                    />                 
                  </div>
                </div>
              </RevealFadeIn>
            </div>
            <div className={classes.introRight}>
              <h1>{eventName}</h1>
              <img src={page?.images?.find((img) => img.id === page?.background?.sys.id)?.file?.url} style={{ position: 'absolute', right: 0, bottom: 0, zIndex: -1 }} />
            </div>
          </div>
        </div>
        {/* <RevealText
          tag="div"
          type="lines"
          value={convertRichText(page.disclaimer)}
          visible={pageAnimationReady}
          lineHeight={1.2}
          delay={2.2}
          className={classes.beginDisclaimer}
        /> */}
      </div>
    )
  }

  /*------------------------------
  Render Content
  ------------------------------*/
  const renderContent = () => {
    return (
      load && (
        <>
          <div className={classes.page}>
            {renderHero()}
          </div>
        </>
      )
    )
  }

  return (
    <LocomotiveScroll
      init={load}
      className={`page pageBegin ${classes.root}`}
      ref={$root}
    >
      {renderHelmet()}
      {renderContent()}
    </LocomotiveScroll>
  )
}

export default LiteBegin
