import {
  DENIED_CAMERA,
  RECEIVE_EXPERIENCE,
  RECEIVE_GEOCODING,
  RECEIVE_SAVE_EXPERIENCE,
  RESET_EXPERIENCE,
  SET_EXPERIENCE,
  SET_CURRENT_EVENT,
  SET_IMAGE_DOWNLOAD,
  SET_IMAGE_GIF_1,
  SET_IMAGE_GIF_2,
  SET_IMAGE_GIF_3,
  SET_IMAGE_GIF_4,
  SET_IMAGE_THUMB,
  SET_SINGLE_EMOTION,
  SET_SPORT,
  SET_UPLIFTER_PROGRAM_ID,
  SET_STARTING_EXPERIENCE,
  SET_ENDED_EXPERIENCE,
  SET_STEP,
  SET_TIME,
  SET_START_TIME,
  SET_END_TIME,
  SET_AGE,
  SET_GENDER,
  SET_EVENT_LOCATION,
  SET_EXERTION,
  SET_LOTTERY,
  SET_PRIZE_MAIL_BODY,
  SET_UUID,
  SET_IMAGE,
  SET_TOTAL_UPLIFT,
  SET_USER_IMAGE,
} from "@/actions/types";

const initialState = {
  isStartingExperience: true,
  isEndedExperience: false,
  step: 1,
  time: 0,
  startTime: 0,
  endTime: 0,
  gender: 0,
  age: 0,
  sport_id: 0,
  uplifter_program_id: 0,
  image: null,
  isCameraDenied: false,
  exertion: 0,
  lottery: false,
  prizeMailBody:"",
  uuid: 0,
  event_location: {
    lat: 0,
    lon: 0,
    city: "",
  },
  emotions: {
    focused: { pre: 0, post: 0, group: "cognitive" },
    alert: { pre: 0, post: 0, group: "cognitive" },
    calm: { pre: 0, post: 0, group: "cognitive" },
    energized: { pre: 0, post: 0, group: "cognitive" },
    positive: { pre: 0, post: 0, group: "emotional" },
    content: { pre: 0, post: 0, group: "emotional" },
    relaxed: { pre: 0, post: 0, group: "emotional" },
    composed: { pre: 0, post: 0, group: "emotional" },
    resilient: { pre: 0, post: 0, group: "emotional" },
    confident: { pre: 0, post: 0, group: "emotional" },
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_STARTING_EXPERIENCE:
      state = {
        ...state,
        isStartingExperience: action.payload,
      };
      break;
    case SET_ENDED_EXPERIENCE:
      state = {
        ...state,
        isEndedExperience: action.payload,
      };
      break;
    case DENIED_CAMERA:
      state = {
        ...state,
        isCameraDenied: true,
      };
      break;
    case SET_CURRENT_EVENT:
      state = {
        ...state,
        event_id: action.id,
        event_name: action.payload,
      };
      break;
    case SET_SPORT:
      state = {
        ...state,
        sport_id: parseInt(action.payload, 10),
      };
      break;
    case SET_UPLIFTER_PROGRAM_ID:
      state = {
        ...state,
        uplifter_program_id: parseInt(action.payload, 10),
      };
      break;
    case SET_STEP:
      state = {
        ...state,
        step: action.payload,
      };
      break;
    case SET_TIME:
      state = {
        ...state,
        time: action.payload,
      };
      break;
    case SET_START_TIME:
      state = {
        ...state,
        startTime: action.payload,
      };
      break;
    case SET_END_TIME:
      state = {
        ...state,
        endTime: action.payload,
      };
      break;
    case SET_AGE:
      state = {
        ...state,
        age: action.payload,
      };
      break;
    case SET_GENDER:
      state = {
        ...state,
        gender: action.payload,
      };
      break;
    case SET_EXERTION:
      state = {
        ...state,
        exertion: action.payload,
      };
      break;
    case SET_LOTTERY:
      state = {
        ...state,
        lottery: action.payload,
      };
      break;
    case SET_PRIZE_MAIL_BODY:
      state = {
        ...state,
        prizeMailBody: action.payload,
      };
      break;
    case SET_UUID:
      state = {
        ...state,
        uuid: action.payload,
      };
      break;
    case SET_IMAGE:
      state = {
        ...state,
        image: action.payload,
      };
      break;
    case SET_IMAGE_DOWNLOAD:
      state = {
        ...state,
        image_download: action.payload,
      };
      break;
    case SET_IMAGE_THUMB:
      state = {
        ...state,
        image: action.payload,
      };
      break;
    case SET_IMAGE_GIF_1:
      state = {
        ...state,
        gif_1: action.payload,
      };
      break;
    case SET_IMAGE_GIF_2:
      state = {
        ...state,
        gif_2: action.payload,
      };
      break;
    case SET_IMAGE_GIF_3:
      state = {
        ...state,
        gif_3: action.payload,
      };
      break;
    case SET_IMAGE_GIF_4:
      state = {
        ...state,
        gif_4: action.payload,
      };
      break;
    case SET_TOTAL_UPLIFT:
      state = {
        ...state,
        total_uplift: action.payload,
      };
      break;
    case SET_USER_IMAGE:
      state = {
        ...state,
        user_image: action.payload,
      };
      break;
    case SET_SINGLE_EMOTION:
      state = {
        ...state,
        emotions: {
          ...state.emotions,
          [action.key]: {
            ...state.emotions[action.key],
            [action.status]: parseFloat(action.payload),
          },
        },
      };
      break;
    case SET_EVENT_LOCATION:
      state = {
        ...state,
        event_location: {
          ...action.payload,
        },
      };
      break;
    case RECEIVE_EXPERIENCE:
      state = {
        ...state,
        ...action.payload,
        uuid: action.uuid,
      };
      break;
    case RESET_EXPERIENCE:
      state = {
        ...initialState,
        ...(state.country && {
          // eslint-disable-line
          country: state.country,
        }),
        ...(state.city && {
          // eslint-disable-line
          city: state.city,
        }),
        ...(state.region && {
          // eslint-disable-line
          region: state.region,
        }),
      };
      break;
    case SET_EXPERIENCE:
      state = {
        ...action.payload.experience,
      };
      break;
    case RECEIVE_SAVE_EXPERIENCE:
      state = {
        ...state,
        uuid: action.payload.uuid,
      };
      break;
    case RECEIVE_GEOCODING:
      state = {
        ...state,
        country: action.payload.countryCode,
        city:
          action.payload.city !== ""
            ? action.payload.city
            : action.payload.locality,
        region: action.payload.principalSubdivision,
      };
      break;
    default:
      return { ...state };
  }
  return { ...state };
};
