import { useRef, useState, useEffect, useContext, useCallback } from "react";
import { createUseStyles } from "react-jss";
import classNames from "classnames";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { Context as GeneralContext } from "@/context";
import { history } from '@/base/store'
import Meta from "@/components/Meta";
import LocomotiveScroll from "@/components/LocomotiveScroll";
import RevealText from "@/components/RevealText";
import RevealFadeIn from "@/components/RevealFadeIn";
import TextField from "@/components/Form/Fields/TextField";
import usePagePrecache from "@/hooks/usePagePrecache";
import * as routerActions from "@/actions/fakerouter";
import * as experienceActions from "@/actions/experience";
import * as contentActions from "@/actions/content";
import * as eventsActions from "@/actions/events";
import * as layerActions from "@/actions/layer";
import { convertRichText } from "@/utils/strings";
import style from "./style";

const useStyles = createUseStyles(style);

const ExpiredSession = () => {
  const {
    setPageAnimationReady,
    pageAnimationReady,
    headerHeight,
    setLogoWhite,
  } = useContext(GeneralContext);
  const [isDataFetched, setDataFetched] = useState(false);

  const $root = useRef();

  /*------------------------------
  Redux Store
  ------------------------------*/
  const { page, events, brandedEvent, fakeLocation, strings, user,currentLanguage } =
    useSelector(
      (state) => ({
        page: state.content["expired-session"] || {},
        fakeLocation: state.fakerouter.location,
        strings: state.options.strings,
        user: state.user,
        currentLanguage:state.locale.currentLanguage
      }),
      shallowEqual
    );

  const classes = useStyles({ headerHeight });

  /*------------------------------
  Redux Actions
  ------------------------------*/
  const dispatch = useDispatch();
  // const moveForward = useCallback(() => dispatch(routerActions.changeLocation('steps')), [dispatch])
  const moveBack = useCallback(
    () => history.push('/'),
    [dispatch]
  );
  const fetchEventImage = useCallback(
    (id) => dispatch(eventsActions.fetchEventImage(id)),
    [dispatch]
  );
  const fetchContent = useCallback(
    (slug) => dispatch(contentActions.fetchContent(slug, "expiredSessionPage")),
    [dispatch]
  );

  //
  let backgroundImage = `/frontend/static/images/general/page-background.png`;
  const resizeBg = () => {
    if (window.innerWidth < 768) {
      backgroundImage = `/frontend/static/images/general/page-mobile-background.png`;
    }
  };
  resizeBg();

  window.addEventListener("resize", () => {
    resizeBg();
  });

  /*------------------------------
  Fetch Page Data
  ------------------------------*/
  useEffect(() => {
    if (Object.keys(page).length === 0) fetchContent("expired-session");
  }, []);

  /*------------------------------
  Check data Fetched
  ------------------------------*/
  useEffect(() => {
      setDataFetched(true);
  }, [page]);
  /*------------------------------
  Preload
  ------------------------------*/
  const [load] = usePagePrecache({
    init: isDataFetched,
    sources: [
      // Object.keys(eventImage).length > 0 && { ...{ type: 'image', src: eventImage.url } },
    ],
    callback: () => setPageAnimationReady(true),
  });

  /*------------------------------
  Reset Logo White
  ------------------------------*/

  /*------------------------------
  Render Helmet
  ------------------------------*/
  const renderHelmet = () => {
    return load && <Meta />;
  };

  const handleClickNext = () => {
    moveForward();
  };

  /*------------------------------
  Render Content
  ------------------------------*/
  const renderChoose = () => {
    return (
      <div className={classes.page} style={{ background: `url(${backgroundImage}) center center no-repeat` }}>
        <div
          className={classNames({
            [classes.hero]: true,
          })}
        >
          <div className={classes.wrapper}>
            <div className={classes.box}>
            <RevealText
                tag="h1"
                type="lines"
                value={page.heading}
                visible={pageAnimationReady}
                lineHeight={1.4}
                delay={0}
                className={classes.title}
              />
              <RevealText
                tag="h1"
                type="lines"
                value={convertRichText(page.bodyCopy)}
                visible={pageAnimationReady}
                lineHeight={1.4}
                delay={0}
                className={classes.hint}
              />
            </div>
            <div className={classes.buttons}>
              <RevealFadeIn delay={1} inview={pageAnimationReady}>
                <button
                  onClick={moveBack}
                  className={classNames({
                    [classes.btn]: true,
                    disabled: false,
                  })}
                >
                  <span>{page.button}</span>
                </button>
              </RevealFadeIn>
            </div>
          </div>
        </div>
      </div>
    );
  };

  /*------------------------------
  Render Content
  ------------------------------*/
  const renderContent = () => {
    return (
      load && (
        <>
          <div className={classes.page}>{renderChoose()}</div>
        </>
      )
    );
  };

  return (
    <>
      <LocomotiveScroll
        init={load}
        className={`page pageChooseEvent ${classes.root}`}
        ref={$root}
      >
        {renderHelmet()}
        {renderContent()}
      </LocomotiveScroll>
    </>
  );
};
export default ExpiredSession;
