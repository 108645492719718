import { createUseStyles, useTheme } from 'react-jss'
import { useContext, useEffect, useState } from 'react'
import gsap from 'gsap'
import { shallowEqual, useSelector } from 'react-redux'
import style from './style'
import { Context as GeneralContext } from '@/context'

const useStyles = createUseStyles(style)

const Tutorial = (isMapReady) => {
  const theme = useTheme()
  const { headerHeight } = useContext(GeneralContext)
  const classes = useStyles({ headerHeight })
  const [isTakingTour, setTakingTour] = useState(undefined)
  const [showTutorial, setShowTutorial] = useState(true)
  const [tipStep, setTipStep] = useState(-1)
  const [newTipStep, setnewTipStep] = useState(0)
  const { strings } = useSelector((state) => ({
    strings: state.options.strings,
  }), shallowEqual)

  const Tips = [{
    idToShow: '',
    description: strings['map.tutorial.tip1'],
  }, {
    idToShow: 'StateOfMindScoreKey',
    description: strings['map.tutorial.tip2'],
  }, {
    idToShow: 'SidebarTabs',
    description: strings['map.tutorial.tip3'],
  }, {
    idToShow: 'SidebarTabs',
    description: strings['map.tutorial.tip4'],
  }, {
    idToShow: 'Map',
    description: strings['map.tutorial.tip5'],
  },
  ]
  useEffect(() => {
    if (isMapReady) {
      gsap.fromTo('#mainQuestion', { opacity: 0 }, { opacity: 1, duration: 1, delay: 1 })
    }
  }, [])
  useEffect(() => {
    if (isTakingTour) {
      setTipStep(0)
      gsap.to('#mainQuestion', { opacity: 0, duration: 0.8, delay: 0.10, onComplete: onCompleteHideTutorial })
    } else if (!isTakingTour) {
      gsap.to('#mainQuestion', { opacity: 0, duration: 0.8, delay: 0.10, onComplete: onCompleteHideTutorial })
    }
    function onCompleteHideTutorial() {
      if (isTakingTour !== undefined) {
        setShowTutorial(false)
      }
    }
  }, [isTakingTour])

  const renderContent = () => {
    if (showTutorial) {
      return (
        <div id="mainQuestion" className={classes.backgroundTutorialQuestion}>
          <div className="divBackground" />
          <div className="divWhiteTutorialQuestion">
            <h1 className="welcomeTitle"> </h1>
            <p className="welcomeDescription">
              {strings['map.tutorial.description']}
            </p>
            <div className="welcomeButtonsDiv">
              <div>
                <button onClick={() => { setTakingTour(true) }} className="welcomeButtonTakeTour">{strings['map.tutorial.takeATour']}</button>
              </div>
              <div>
                <button onClick={() => { setTakingTour(false) }} className="welcomeButtonSkip">{strings['map.tutorial.skip']}</button>
              </div>
            </div>
            >
          </div>
        </div>
      )
    }
  }
  useEffect(() => {
    // Tooltips animation and movement between elements
    if (tipStep === 0) {
      if (window.visualViewport.width >= 720) {
        const elementToAttach = document.getElementById('headerForTooltip1')
        const rect = elementToAttach.getBoundingClientRect()

        gsap.to('#tooltipBox', { left: rect.left + (rect.width) + 50, top: rect.top + 40, duration: 0.1, zIndex: 200 })

        const sidebarRect = document.getElementById('sidebarIdForTutorial').getBoundingClientRect()
        const mapRect = document.getElementById('mapIdForTutorial').getBoundingClientRect()
        const barKeyRect = document.getElementById('barKeyIdForTutorial').getBoundingClientRect()
        gsap.to('#TooltipSelector1', { left: sidebarRect.width + 30, top: 100, width: mapRect.width - (sidebarRect.width + 70), height: mapRect.height - (barKeyRect.height + 150), duration: 0.1, zIndex: 200 })
        gsap.to('#TooltipSelector2', { left: sidebarRect.width + 30, top: 100, width: mapRect.width - (sidebarRect.width + 70), height: mapRect.height - (barKeyRect.height + 150), duration: 0.1, zIndex: 200 })
      } else {
        const elementToAttach = document.getElementById('headerForTooltip1')
        const rect = elementToAttach.getBoundingClientRect()

        gsap.to('#tooltipBox', { left: rect.left + 50, top: rect.height + 40, duration: 0.1, zIndex: 200, width: '60%' })

        const sidebarRect = document.getElementById('SidebarScoreMobile').getBoundingClientRect()
        const mapRect = document.getElementById('mapIdForTutorial').getBoundingClientRect()
        const SidebarContentMobileRect = document.getElementById('SidebarContentMobile').getBoundingClientRect()
        gsap.to('#TooltipSelector1', { left: mapRect.left + 40, top: rect.height + 20, width: mapRect.width - 100, height: mapRect.height - (sidebarRect.height + SidebarContentMobileRect.height + 120), duration: 0.1, zIndex: 200 })
        gsap.to('#TooltipSelector2', { left: mapRect.left + 40, top: rect.height + 20, width: mapRect.width - 100, height: mapRect.height - (sidebarRect.height + SidebarContentMobileRect.height + 120), duration: 0.1, zIndex: 200 })
      }
    } else if (tipStep === 1) {
      if (window.visualViewport.width >= 720) {
        const elementToAttach = document.getElementById('barKeyIdForTutorial')
        const rect = elementToAttach.getBoundingClientRect()
        const tooltipBoxRect = document.getElementById('tooltipBox').getBoundingClientRect()

        gsap.to('#tooltipBox', { left: rect.left - 20, top: rect.top - (rect.height + tooltipBoxRect.height - 40), duration: 0.1, zIndex: 200, width: '28%' })
        gsap.to('#TooltipSelector1', { left: rect.left - 17, top: rect.top - 10, width: rect.width + 30, height: rect.height + 20, duration: 0.1, zIndex: 200 })
        gsap.to('#TooltipSelector2', { left: rect.left - 17, top: rect.top - 10, width: rect.width + 30, height: rect.height + 20, duration: 0.1, zIndex: 200 })
      } else {
        setTipStep(tipStep + 1)
      }
    } else if (tipStep === 2) {
      if (window.visualViewport.width >= 720) {
        const elementToAttach = document.getElementById('NavIdForTutorialfocus')
        const rect = elementToAttach.getBoundingClientRect()

        gsap.to('#tooltipBox', { left: rect.left + (rect.width) + 50, top: rect.top, duration: 0.1, zIndex: 200 })
        gsap.to('#TooltipSelector1', { left: rect.left - 17, top: rect.top - 10, width: rect.width + 30, height: rect.height + 20, duration: 0.1, zIndex: 200 })
        gsap.to('#TooltipSelector2', { left: rect.left - 17, top: rect.top - 10, width: rect.width + 30, height: rect.height + 20, duration: 0.1, zIndex: 200 })
      } else {
        const elementToAttach = document.getElementById('SidebarContentMobile')
        const rect = elementToAttach.getBoundingClientRect()
        const tooltipBoxRect = document.getElementById('tooltipBox').getBoundingClientRect()

        gsap.to('#tooltipBox', { left: rect.left + 10, top: rect.top - (tooltipBoxRect.height + 20), duration: 0.1, zIndex: 200 })
        gsap.to('#TooltipSelector1', { left: rect.left, top: rect.top, width: rect.width, height: rect.height, duration: 0.1, zIndex: 200 })
        gsap.to('#TooltipSelector2', { left: rect.left, top: rect.top, width: rect.width, height: rect.height, duration: 0.1, zIndex: 200 })
      }
    } else if (tipStep === 3) {
      if (window.visualViewport.width >= 720) {
        const elementToAttach = document.getElementById('IdForTutorialSelectMind')
        const rect = elementToAttach.getBoundingClientRect()

        gsap.to('#tooltipBox', { left: rect.left + (rect.width) + 50, top: rect.top, duration: 0.1, zIndex: 200 })
        gsap.to('#TooltipSelector1', { left: rect.left - 17, top: rect.top - 10, width: rect.width + 30, height: rect.height + 20, duration: 0.1, zIndex: 200 })
        gsap.to('#TooltipSelector2', { left: rect.left - 17, top: rect.top - 10, width: rect.width + 30, height: rect.height + 20, duration: 0.1, zIndex: 200 })
      } else {
        setTipStep(tipStep + 1)
      }
    } else if (tipStep === 4) {
      if (window.visualViewport.width >= 720) {
        const elementToAttach = document.getElementById('headerForTooltip1')
        const rect = elementToAttach.getBoundingClientRect()

        const sidebarRect = document.getElementById('sidebarIdForTutorial').getBoundingClientRect()
        const mapRect = document.getElementById('mapIdForTutorial').getBoundingClientRect()
        const barKeyRect = document.getElementById('barKeyIdForTutorial').getBoundingClientRect()

        gsap.to('#tooltipBox', { left: rect.left + (rect.width) + 50, top: mapRect.height / 2 - 150, duration: 0.1, zIndex: 200, width: '20%' })
        gsap.to('#TooltipSelector1', { left: sidebarRect.width + ((mapRect.width - sidebarRect.width) / 2) - 250, top: mapRect.height / 2 - 240, width: 300, height: 300, duration: 0.1, zIndex: 200 })
        gsap.to('#TooltipSelector2', { left: sidebarRect.width + ((mapRect.width - sidebarRect.width) / 2) - 250, top: mapRect.height / 2 - 240, width: 300, height: 300, duration: 0.1, zIndex: 200 })
      } else {
        const elementToAttach = document.getElementById('headerForTooltip1')
        const rect = elementToAttach.getBoundingClientRect()

        gsap.to('#tooltipBox', { left: rect.left + 50, top: rect.height + 40, duration: 0.1, zIndex: 200, width: '60%' })

        const sidebarRect = document.getElementById('SidebarScoreMobile').getBoundingClientRect()
        const mapRect = document.getElementById('mapIdForTutorial').getBoundingClientRect()
        const SidebarContentMobileRect = document.getElementById('SidebarContentMobile').getBoundingClientRect()
        gsap.to('#TooltipSelector1', { left: mapRect.left + 40, top: rect.height + 20, width: mapRect.width - 100, height: mapRect.height - (sidebarRect.height + SidebarContentMobileRect.height + 120), duration: 0.1, zIndex: 200 })
        gsap.to('#TooltipSelector2', { left: mapRect.left + 40, top: rect.height + 20, width: mapRect.width - 100, height: mapRect.height - (sidebarRect.height + SidebarContentMobileRect.height + 120), duration: 0.1, zIndex: 200 })
      }
    }
    gsap.fromTo('#toolTipTutorial', { opacity: 0 }, { opacity: 1, duration: 0.8, delay: 0.1 })
  }, [tipStep])

  useEffect(() => {
    if (newTipStep !== 0) {
      gsap.fromTo('#toolTipTutorial', { opacity: 1 }, { opacity: 0, duration: 1, delay: 0 })
      setTimeout(() => {
        setTipStep(newTipStep)
      }, 1000)
    }
  }, [newTipStep])
  const renderTooltip = () => {
    if (tipStep >= 0 && isTakingTour !== false) {
      return (
        <div id="toolTipTutorial" className={classes.tutorialToolTip}>
          <div id="TooltipSelector1" className="divBackground" />
          <div id="TooltipSelector2" className="divBackground2" />
          <div id="tooltipBox" className="divBlueTutorialQuestion">
            <p className="tooltipDescription">
              {Tips[tipStep].description}
            </p>
            <div className="tooltipButtonsDiv">
              {
                tipStep !== 4 ? <button onClick={() => { setnewTipStep(tipStep + 1) }} className="tooltipButtonContinue">{strings['map.tutorial.nextTip']}</button> : ''
              }
              <button onClick={() => { setTakingTour(false) }} className="tooltipButtonClose">{strings['map.tutorial.close']}</button>
            </div>
          </div>
        </div>
      )
    }
  }
  return (
    <>
      {renderContent()}
      {renderTooltip()}
    </>
  )
}

export default Tutorial
