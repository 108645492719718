import { useRef, useEffect, useContext, useState, useCallback } from 'react'
import { createUseStyles } from 'react-jss'
import { useSelector, shallowEqual, useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { Context as GeneralContext } from '@/context'
import Meta from '@/components/Meta'
import RevealText from '@/components/RevealText'
import RevealFadeIn from '@/components/RevealFadeIn'
import LocomotiveScroll from '@/components/LocomotiveScroll'
import useHandleAllMediaWithCb from '@/hooks/useHandleAllMediaWithCb'
import usePagePrecache from '@/hooks/usePagePrecache'
import * as contentActions from '@/actions/content'
import { convertRichText } from '@/utils/strings'
import { getSlug } from '@/utils/path'
import style from './style'

const useStyles = createUseStyles(style)

const Generic = () => {
  const { setPageAnimationReady, pageAnimationReady, headerHeight } = useContext(GeneralContext)
  const classes = useStyles({ headerHeight })
  const $root = useRef()
  const location = useLocation()
  const stub = useRef(getSlug(location.pathname))

  /*------------------------------
  Redux Store
  ------------------------------*/
  const { currentLanguage, page } = useSelector((state) => ({
    page: state.content[stub.current] || {},
    currentLanguage: state.locale.currentLanguage,
  }), shallowEqual)

  /*------------------------------
  Redux Actions
  ------------------------------*/
  const dispatch = useDispatch()
  const fetchPage = useCallback((slug) => dispatch(contentActions.fetchContent(slug, 'page')), [dispatch])

  /*------------------------------
  Fetch Page Data
  ------------------------------*/
  useEffect(() => {
    if (Object.keys(page).length === 0) fetchPage(stub.current)
  }, [])
  useEffect(() => {
    if (Object.keys(page).length > 0 )
      fetchPage(stub.current);
  }, [currentLanguage])
  /*------------------------------
  Preload
  ------------------------------*/
  const [realLoad, setRealLoad] = useState(false)
  const [load] = usePagePrecache({ // eslint-disable-line
    init: true,
    sources: [],
    callback: () => setPageAnimationReady(true),
  })

  /*------------------------------
  Check data Fetched
  ------------------------------*/
  useEffect(() => {
    if (Object.keys(page).length > 0) setRealLoad(true)
  }, [page])

  /*------------------------------
  Executes a callback on loading each image
  ------------------------------*/
  useHandleAllMediaWithCb({
    init: realLoad,
    ref: $root.current?.ref,
  })

  /*------------------------------
  Render Helmet
  ------------------------------*/
  const renderHelmet = () => {
    return realLoad && <Meta />
  }

  /*------------------------------
  Render Title
  ------------------------------*/
  const renderTitle = () => {
    return (
      <div className={classes.title}>
        <RevealText
          tag="h1"
          type="lines"
          value={page.title}
          visible={pageAnimationReady}
          lineHeight={1.2}
        />
      </div>
    )
  }

  /*------------------------------
  Render Text
  ------------------------------*/
  const renderText = () => {
    return (
      <div className={classes.text}>
        <RevealFadeIn
          delay={0.5}
          visible={pageAnimationReady}
        >
          <p dangerouslySetInnerHTML={{ __html: convertRichText(page.text) }} />
        </RevealFadeIn>
      </div>
    )
  }

  /*------------------------------
  Render Content
  ------------------------------*/
  const renderContent = () => {
    return realLoad && (
      <>
        <div className={classes.page}>
          {renderTitle()}
          {renderText()}
        </div>
      </>
    )
  }

  return (
    <LocomotiveScroll
      init={realLoad}
      className={`page pageGeneric ${classes.root}`}
      ref={$root}
    >
      {renderHelmet()}
      {renderContent()}
    </LocomotiveScroll>
  )
}

export default Generic
