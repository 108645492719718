import { useEffect, useRef, useState, useCallback, useContext } from 'react'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import { createUseStyles } from 'react-jss'
import gsap from 'gsap'
import classNames from 'classnames'
import RevealText from '@/components/RevealText'
import RevealFadeIn from '@/components/RevealFadeIn'
import { useResize } from '@/components/Handlers'
import usePrevious from '@/hooks/usePrevious'
import { convertRichText } from '@/utils/strings'
import { Context as LoadingContext } from '@/context/loading'
import * as layerActions from '@/actions/layer'
import * as experienceActions from '@/actions/experience'
import * as contentActions from '@/actions/content'
import style from './style'
import React from 'react'

const useStyles = createUseStyles(style)

const CongratsModal = () => {
  const { isLoaderExited } = useContext(LoadingContext)
  const $root = useRef()
  const rootBounds = useRef()
  const [entered, setEntered] = useState(false)
  const [tooltip, setTooltip] = useState(false)
  const [modalHeight, setModalHeight] = useState(0)
  const classes = useStyles({ modalHeight })
  const [ready, setReady] = useState(false)

  /*------------------------------
  Redux Connect
  ------------------------------*/
  const { page, isLayerOpen, location, strings,currentLanguage } = useSelector((state) => ({
    page: state.content['user-gallery-popup2'] || {},
    location: state.fakerouter.location,
    strings: state.options.strings,
    isLayerOpen: state.layer.layers.some((layer) => layer.id === 'congrats' && layer.isOpen),
    experience: state.experience,
    schema: state.locale.schema,
    currentLanguage: state.locale.currentLanguage || 'en-US',
  }), shallowEqual)

  /*------------------------------
  Redux Actions
  ------------------------------*/
  const dispatch = useDispatch()
  const closeLayer = useCallback(() => dispatch(layerActions.closeLayer({ id: 'congrats' })), [dispatch])
  const fetchContent = useCallback((slug) => dispatch(contentActions.fetchContent(slug,'userGalleryPopup2')), [dispatch])
  const setUUID = useCallback((value) => dispatch(experienceActions.setUUID(value)), [dispatch])

  /*------------------------------
  Fetch Content
  ------------------------------*/
  useEffect(() => {
    if (Object.keys(page).length === 0) fetchContent('user-gallery-popup2')
  }, [])

  useEffect(() => {
    if (Object.keys(page).length > 0 )
      fetchContent('user-gallery-popup2');
  }, [currentLanguage])
  /*------------------------------
  Set Ready
  ------------------------------*/
  useEffect(() => {
    if (Object.keys(page).length > 0 && isLoaderExited) setReady(true)
  }, [page, isLoaderExited])

  /*------------------------------
  Get Header Height
  ------------------------------*/
  useResize(() => {
    if ($root.current) {
      rootBounds.current = $root.current.getBoundingClientRect()
      setModalHeight($root.current.getBoundingClientRect().height)
    }
  })
  useEffect(() => {
    if (ready) {
      setTimeout(() => {
        rootBounds.current = $root.current.getBoundingClientRect()
        setModalHeight($root.current.getBoundingClientRect().height)
      }, 1000)
    }
  }, [ready])

  /*------------------------------
  Close Modal on change location
  ------------------------------*/
  const prevLocation = usePrevious(location)
  useEffect(() => {
    if ((prevLocation !== location) && isLayerOpen) {
      closeLayer()
    }
  }, [location, isLayerOpen])

  /*------------------------------
  Handle Keydown
  ------------------------------*/
  const handleKeyDown = (e) => {
    if (e.key === 'Escape' && e.keyCode === 27 && isLayerOpen) closeLayer()
  }
  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown)
    return () => window.removeEventListener('keydown', handleKeyDown)
  }, [isLayerOpen])

  /*------------------------------
  Open Modal Animation
  ------------------------------*/
  const openModal = () => {
    gsap.killTweensOf([$root.current])
    const tl = gsap.timeline({ ease: 'power3.inOut', onComplete: () => setEntered(true) })
    tl
      .to($root.current, {
        duration: 0.5,
        y: 0,
        autoAlpha: 1,
      })
  }

  /*------------------------------
  Close Modal Animation
  ------------------------------*/
  const closeModal = () => {
    setUUID(0)
    setEntered(false)
    gsap.killTweensOf([$root.current])
    gsap.to($root.current, {
      duration: 0.5,
      y: 50,
      autoAlpha: 0,
    })
  }

  /*------------------------------
  Close Layer when isLayerOpen changed
  ------------------------------*/
  const prevIsOpen = usePrevious(isLayerOpen)
  useEffect(() => {
    if (prevIsOpen !== undefined && isLayerOpen) openModal()
    if (prevIsOpen !== undefined && !isLayerOpen) closeModal()
  }, [isLayerOpen])

  return ready && (
    <div
      className={classes.root}
      ref={$root}
      role="dialog"
      aria-modal="true"
    >
      <div className={classes.box}>
        <RevealText
          tag="h1"
          type="lines"
          value={page.heading}
          visible={entered}
          lineHeight={1.4}
          delay={0}
          className={classes.title}
        />

        <RevealText
          tag="div"
          type="lines"
          value={page.subHeading}
          visible={entered}
          lineHeight={1.333}
          delay={0.5}
          className={classes.text}
        />

        <img src={page?.images?.find((img) => img.id === page?.image?.sys.id)?.file?.url} style={{marginTop: -20, marginBottom: -40}} />

        <RevealText
          tag="div"
          type="lines"
          value={convertRichText(page.bodyCopy)}
          visible={entered}
          lineHeight={1.333}
          delay={0.5}
          className={classes.disclaimer}
        />
        
        <RevealFadeIn delay={0.5} inview={entered}>
          <button className={classes.btn} onClick={closeLayer} >
            {page.backButton}
          </button>
          <button onClick={closeLayer}
            className={classNames({
              [classes.btn]: true,
              white: true,
            })}
          >
            {page.findOutMore}
          </button>
        </RevealFadeIn>
      </div>
    </div>
  )
}

export default CongratsModal
