import { useRef, useState, useEffect, useContext, useCallback } from 'react'
import { createUseStyles } from 'react-jss'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import { useHistory } from 'react-router'
import { Context as GeneralContext } from '@/context'
import Meta from '@/components/Meta'
import RevealText from '@/components/RevealText'
import RevealFadeIn from '@/components/RevealFadeIn'
import LocomotiveScroll from '@/components/LocomotiveScroll'
import usePagePrecache from '@/hooks/usePagePrecache'
import * as contentActions from '@/actions/content'
import { convertRichText } from '@/utils/strings'
import style from './style'

const useStyles = createUseStyles(style)

const FacialScanDenied = () => {
  const { setPageAnimationReady, pageAnimationReady, headerHeight } = useContext(GeneralContext)
  const classes = useStyles({ headerHeight })
  const [isDataFetched, setDataFetched] = useState(false)
  const $root = useRef()
  const history = useHistory()

  /*------------------------------
  Redux Store
  ------------------------------*/
  const { page, fakeLocation, strings,currentLanguage } = useSelector((state) => ({
    page: state.content['facial-scan-denied'] || {},
    fakeLocation: state.fakerouter.location,
    strings: state.options.strings,
    currentLanguage:state.locale.currentLanguage
  }), shallowEqual)

  /*------------------------------
  Redux Actions
  ------------------------------*/
  const dispatch = useDispatch()
  const fetchContent = useCallback((slug) => dispatch(contentActions.fetchContent(slug)), [dispatch])

  /*------------------------------
  Fetch Page Data
  ------------------------------*/
  useEffect(() => {
    if (Object.keys(page).length === 0) fetchContent(fakeLocation)
  }, [])
  useEffect(() => {
    if (Object.keys(page).length > 0 )
      fetchContent(slug);
  }, [currentLanguage])
  /*------------------------------
  Check data Fetched
  ------------------------------*/
  useEffect(() => {
    if (Object.keys(page).length > 0) setDataFetched(true)
  }, [page])

  /*------------------------------
  Preload
  ------------------------------*/
  const [load] = usePagePrecache({
    init: isDataFetched,
    sources: [],
    callback: () => setPageAnimationReady(true),
  })

  /*------------------------------
  Render Helmet
  ------------------------------*/
  const renderHelmet = () => {
    return load && <Meta />
  }

  /*------------------------------
  Handle Click Next
  ------------------------------*/
  const handleClickNext = () => {
    // Reload page with react-router
    history.go(0)
  }

  /*------------------------------
  Render Hero
  ------------------------------*/
  const renderHero = () => {
    return (
      <div className={classes.hero}>
        <div className={classes.wrapper}>
          <RevealText
            tag="h1"
            type="lines"
            value={convertRichText(page.title)}
            visible={pageAnimationReady}
            lineHeight={1.2}
            delay={0}
            className={classes.beginTitle}
          />
          <RevealText
            tag="div"
            type="lines"
            value={convertRichText(page.text)}
            visible={pageAnimationReady}
            lineHeight={1.333}
            delay={1.3}
            className={classes.beginText}
          />
          <RevealFadeIn
            delay={2}
            visible={pageAnimationReady}
          >
            <button
              onClick={handleClickNext}
              className={classes.btn}
            >
              <span>{page.nextLabel || strings['general.continue']}</span>
            </button>
          </RevealFadeIn>
        </div>
        <RevealText
          tag="div"
          type="lines"
          value={convertRichText(page.disclaimer)}
          visible={pageAnimationReady}
          lineHeight={1.2}
          delay={2.2}
          className={classes.beginDisclaimer}
        />
      </div>
    )
  }

  /*------------------------------
  Render Content
  ------------------------------*/
  const renderContent = () => {
    return (
      load && (
        <>
          <div className={classes.page}>
            {renderHero()}
          </div>
        </>
      )
    )
  }

  return (
    <LocomotiveScroll
      init={load}
      className={`page pageFacialScanDenied ${classes.root}`}
      ref={$root}
    >
      {renderHelmet()}
      {renderContent()}
    </LocomotiveScroll>
  )
}

export default FacialScanDenied
