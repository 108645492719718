import axios from "axios";
import { REQUEST_QUESTIONS, LITE_RECEIVE_QUESTIONS } from "@/actions/types";
import { API, CONTENTFUL_SPACE, CONTENTFUL_TOKEN } from "@/constants";

const requestQuestions = () => ({ type: REQUEST_QUESTIONS });

const receiveQuestions = (data) => ({
  type: LITE_RECEIVE_QUESTIONS,
  payload: data,
});
const fetchEmotions = async () =>  {
  const params = {
    params: {
      access_token: CONTENTFUL_TOKEN,
      content_type: 'emotions', // to test updated content, change as questions
    },
  };
  const emotionsRes = await axios.get(`${API.CMS}/${CONTENTFUL_SPACE}/entries`, params)
  return emotionsRes.data.items;
}
const fetchQuestions = () => async (dispatch, getState) => {
  dispatch(requestQuestions());
  const params = {
    params: {
      access_token: CONTENTFUL_TOKEN,
      content_type: 'litePostQuestions', // to test updated content, change as questions
      locale: getState().locale.currentLanguage,
      "fields.slug": "lite-questions",
    },
  };
  const response = await axios.get(
    `${API.CMS}/${CONTENTFUL_SPACE}/entries`,
    params
  );
  const fields = response.data.items[0].fields;
  const emotionFieldKeys = Object.keys(fields).filter((key)=>key.includes('emotion'))
  const emotionContent = await fetchEmotions();
  emotionFieldKeys.map((e)=>{
    fields[e].key =  emotionContent.find((em)=>em.sys.id == fields[e].sys.id).fields.key
    fields[e].group =  emotionContent.find((em)=>em.sys.id == fields[e].sys.id).fields.group
  });
  dispatch(receiveQuestions(response.data.items[0].fields));
};

export { fetchQuestions };
