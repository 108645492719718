import axios from 'axios'
import { REQUEST_CONTENT, RECEIVE_CONTENT, REQUEST_CONTENT_IMAGE } from '@/actions/types'
import { API, CONTENTFUL_SPACE, CONTENTFUL_TOKEN } from '@/constants'

const requestContent = () => ({ type: REQUEST_CONTENT })

const receiveContent = (data, slug) => ({
  type: RECEIVE_CONTENT,
  payload: data,
  key: slug,
})

const requestImage = () => ({ type: REQUEST_CONTENT_IMAGE })

const fetchImage = (id) => async (dispatch, getState) => {
  dispatch(requestImage())
  const params = {
    params: {
      access_token: CONTENTFUL_TOKEN,
      locale: getState().locale.currentLanguage,
    },
  }
  const response = await axios.get(`${API.CMS}/${CONTENTFUL_SPACE}/assets/${id}`, params)
  return { ...response.data.fields, id }
}

const fetchContent = (slug, type = 'experience') => async (dispatch, getState) => {
  dispatch(requestContent())
  const params = {
    params: {
      access_token: CONTENTFUL_TOKEN,
      content_type: type,
      locale: getState().locale.currentLanguage ?? document.documentElement.lang,
      'fields.slug': slug,
    },
  }
  const response = await axios.get(`${API.CMS}/${CONTENTFUL_SPACE}/entries`, params)
  const page = response.data.items[0].fields
  const images = []
  const list = Object.values(page).filter((it) => it.sys)
  if (list.length > 0) {
    list
      .map(async (i) => {
        images.push(await dispatch(fetchImage(i.sys.id)))
        await dispatch(receiveContent({ ...page, images }, slug))
      })
  } else {
    await dispatch(receiveContent(page, slug))
  }
}
const fetchEventContent = (id,slug, type) => async (dispatch, getState) => {
  const params = {
    params: {
      access_token: CONTENTFUL_TOKEN,
      content_type: type,
      locale: getState().locale.currentLanguage,
      'sys.id': id,
    },
  }
  const response = await axios.get(`${API.CMS}/${CONTENTFUL_SPACE}/entries`, params)
  const page = response.data.items[0].fields
  const images = []
  const list = Object.values(page).filter((it) => it.sys)
  if (list.length > 0) {
    list
      .map(async (i) => {
        images.push(await dispatch(fetchImage(i.sys.id)))
        await dispatch(receiveContent({ ...page, images }, slug))
      })
  } else {
    await dispatch(receiveContent(page, slug))
  }
}
const fetchBackground = () => async (dispatch, getState) => {
  dispatch(requestContent())
  let params = {
    params: {
      access_token: CONTENTFUL_TOKEN,
      content_type: 'backgroundPages',
      locale: getState().locale.currentLanguage,
    },
  }
  const response = await axios.get(`${API.CMS}/${CONTENTFUL_SPACE}/entries`, params)
  let items = response.data.items;
  let page = items;
  page.sort((first,second)=>Number(first.fields.slug.substr(-1))-Number(second.fields.slug.substr(-1)))
  params = {
    params: {
      access_token: CONTENTFUL_TOKEN,
      content_type: 'glossaryPage',
      locale: getState().locale.currentLanguage,
    },
  }
  const resp = await axios.get(`${API.CMS}/${CONTENTFUL_SPACE}/entries`, params)
  page[3] =  resp.data.items[0];
  await Promise.all(page.map(async(background,index) =>  {
    const images = []
    const list = Object.values(background.fields).filter((it) => it.sys)
    if (list.length > 0) {
      return await Promise.all((list)
        .map(async (i,listIndex) => {
          images.push(await dispatch(fetchImage(i.sys.id)))
          if((list.length-1)===listIndex){
            page[index] = { ...page[index].fields, images }
          }
        }))
    }else{
      return page[index] = { ...page[index].fields }
    }
  }));
  await dispatch(receiveContent(page, 'backgroundPages'))
}
export {
  fetchContent,
  fetchEventContent,
  fetchBackground
}
