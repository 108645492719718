import axios from 'axios'
import { REQUEST_EVENTS, RECEIVE_DAILY_EVENTS, RECEIVE_TOTAL_EVENTS, REQUEST_EVENT_IMAGE, RECEIVE_EVENT_IMAGE, REQUEST_TAGS, RECEIVE_TAGS, SET_COUNTRY_CODE, SET_CURRENT_TAG } from '@/actions/types'
import { API, CONTENTFUL_SPACE, CONTENTFUL_TOKEN } from '@/constants'

const requestEvents = () => ({ type: REQUEST_EVENTS })
const requestTags = () => ({ type: REQUEST_TAGS })

const receiveDailyEvents = (data) => ({
  type: RECEIVE_DAILY_EVENTS,
  payload: data,
})

const receiveTotalEvents = (data) => ({
  type: RECEIVE_TOTAL_EVENTS,
  payload: data,
})

const receiveTags = (data) => ({
  type: RECEIVE_TAGS,
  payload: data,
})

const fetchEvents = (today, tag) => async (dispatch, getState) => {
  const countryTag = `country_${(localStorage.getItem('country_code') || 'undefined').toLowerCase()}`
  tag += `,${countryTag}`
  dispatch(requestEvents())
  const params = {
    params: {
      access_token: CONTENTFUL_TOKEN,
      content_type: 'event',
      locale: getState().locale.currentLanguage,
      ...(today && {
        'fields.startDate[lte]': today, // '2021-06-01'
        'fields.endDate[gte]': today, // '2021-06-01'
      }),
      ...(tag && {
        'metadata.tags.sys.id[in]': tag,
      }),
    },
  }
  const response = await axios.get(`${API.CMS}/${CONTENTFUL_SPACE}/entries`, params)
  const filteredResponse = response.data.items.filter((it)=>it.sys.id!=='1QNJUjr8Sr9ZWMJ8lOaWAF')
  if (today) {
    dispatch(receiveDailyEvents(filteredResponse))
  } else {
    dispatch(receiveTotalEvents(filteredResponse))
  }
}

const fetchTags = () => async (dispatch) => {
  dispatch(requestTags())
  const params = {
    params: {
      access_token: CONTENTFUL_TOKEN,
    },
  }
  const response = await axios.get(`${API.CMS}/${CONTENTFUL_SPACE}/tags`, params)
  dispatch(receiveTags(response.data.items))
}

const requestImage = () => ({ type: REQUEST_EVENT_IMAGE })

const receiveEventImage = (data, key) => ({
  type: RECEIVE_EVENT_IMAGE,
  payload: data,
  key,
})

const fetchEventImage = (id) => async (dispatch, getState) => {
  dispatch(requestImage())
  const params = {
    params: {
      access_token: CONTENTFUL_TOKEN,
      locale: getState().locale.currentLanguage,
    },
  }
  const response = await axios.get(`${API.CMS}/${CONTENTFUL_SPACE}/assets/${id}`, params)
  dispatch(receiveEventImage(response.data.fields.file, 'eventImage'))
}

const fetchSelectedEventImage = (id) => async (dispatch, getState) => {
  dispatch(requestImage())
  const params = {
    params: {
      access_token: CONTENTFUL_TOKEN,
      locale: getState().locale.currentLanguage,
    },
  }
  const response = await axios.get(`${API.CMS}/${CONTENTFUL_SPACE}/assets/${id}`, params)
  const { file } = response.data.fields
  dispatch(receiveEventImage(file, 'selectedEventImage'))
  return file
}

const fetchEventLogo = (id) => async (dispatch, getState) => {
  dispatch(requestImage())
  const params = {
    params: {
      access_token: CONTENTFUL_TOKEN,
      locale: getState().locale.currentLanguage,
    },
  }
  const response = await axios.get(`${API.CMS}/${CONTENTFUL_SPACE}/assets/${id}`, params)
  dispatch(receiveEventImage(response.data.fields.file, 'eventLogo'))
  const { file } = response.data.fields
  return file;
}

const fetchExistentCountryCode = () => {
  const currentCountryCode = localStorage.getItem('country_code') || null
  return ({
    type: SET_COUNTRY_CODE,
    payload: currentCountryCode,
  })
}

const setCountryCode = (code) => {
  localStorage.setItem('country_code', code)
  return ({
    type: SET_COUNTRY_CODE,
    payload: code,
  })
}

const setCurrentTag = (tag) => {
  return ({
    type: SET_CURRENT_TAG,
    payload: tag,
  })
}

export {
  fetchEvents,
  fetchTags,
  fetchEventImage,
  fetchSelectedEventImage,
  fetchEventLogo,
  fetchExistentCountryCode,
  setCountryCode,
  setCurrentTag,
}
