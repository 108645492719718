import { useCallback, useEffect } from 'react'
import { createUseStyles } from 'react-jss'
import { useSelector, shallowEqual, useDispatch } from 'react-redux'
import * as contentActions from '@/actions/content'

import stylo from './style'
import useStore from '@/base/zustand'

const useStyles = createUseStyles(stylo)

const Overlay = ({ style, isVisible, children }) => {
  const classes = useStyles({ })
  return (
    <div className={`${classes.overlay} ${isVisible ? classes.overlayVisible : ''}`} style={style}>
      {children}
    </div>
  )
}

const Tooltip = ({ style, isVisible, children }) => {
  const {
    setTooltipOpen,
    tooltipStep,
    setTooltipStep,
    setTooltipText,
  } = useStore()
  const dispatch = useDispatch()
  const { page } = useSelector((state) => ({
    page: state.content['result-page'],
  }), shallowEqual)
  const fetchPage = useCallback((slug, type) => dispatch(contentActions.fetchContent(slug, type)), [dispatch])
  useEffect(() => {
    if (!page) fetchPage('result-page', 'resultPage')
  }, [])
  const tempCopy = [
    page.tooltipPostExercise,
    page.tooltipEmotionBar,
    `${page.tooltipTotalUplift} <br /> ${page.tooltipFormula}`,
  ]
  const onNextTipClick = () => {
    setTooltipOpen(false)
    const nextStep = tooltipStep + 1
    setTimeout(() => {
      setTooltipStep(nextStep)
    }, 400)
    setTimeout(() => {
      setTooltipOpen(true)
    }, 750)
  }
  const onCloseClick = () => {
    setTooltipOpen(false)
  }
  useEffect(() => {
    setTooltipText(tempCopy[tooltipStep % tempCopy.length])
  }, [tooltipStep])

  const classes = useStyles({ })
  return (
    <div className={`${classes.tooltip} ${isVisible ? classes.tooltipVisible : ''} tooltip${tooltipStep}`} style={style}>
      {/* <p>
        {children}
      </p> */}
      <p dangerouslySetInnerHTML={{ __html: children }} />
      <div className={classes.buttonRow}>
        {(tooltipStep < 2) && (
          <button
            className={`${classes.btn} white`}
            style={{ boxShadow: 'none' }}
            onClick={onNextTipClick}
          >
            <span>
              {page.nextTipButton}
            </span>
          </button>
        )}
        <button
          className={classes.btn}
          onClick={onCloseClick}
        >
          <span>
            {page.closeTipButton}
          </span>
        </button>
      </div>
    </div>

  )
}

const TooltipOverlay = (page) => {
  const {
    isTooltipOpen,
    tooltipBounds,
    tooltipText,
  } = useStore()
  // TODO: Replace with translations
  return (
    <>
      <Overlay
        page={page}
        isVisible={isTooltipOpen}
        style={{
          '--bounds-left': tooltipBounds.left,
          '--bounds-top': tooltipBounds.top,
          '--bounds-right': tooltipBounds.right,
          '--bounds-bottom': tooltipBounds.bottom,
        }}
      />
      <Tooltip
        page={page}
        isVisible={isTooltipOpen}
        style={{
          '--bounds-left': tooltipBounds.left,
          '--bounds-top': tooltipBounds.top,
          '--bounds-right': tooltipBounds.right,
          '--bounds-bottom': tooltipBounds.bottom,
        }}
      >
        {tooltipText}
      </Tooltip>
    </>
  )
}

export default TooltipOverlay
