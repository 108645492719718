import { Fragment, useEffect, useContext, useRef, useLayoutEffect, useState, useCallback } from 'react'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import { useLocation, useHistory } from 'react-router-dom'
import classNames from 'classnames'
import { createUseStyles } from 'react-jss'
import gsap from 'gsap'
import ally from 'ally.js'
import { Context } from '@/context'
import DelayLink from '@/components/DelayLink'
import ParseText from '@/components/ParseText'
import LanguageSelectField from '@/components/LanguageSelectField'
import usePrevious from '@/hooks/usePrevious'
import { getSlug } from '@/utils/path'
import { decodeEntities } from '@/utils/decodeEntities'
import * as layerActions from '@/actions/layer'
import * as userActions from '@/actions/user'
import * as experienceActions from '@/actions/experience'
import * as localeActions from '@/actions/locale'
import { convertRichText } from '@/utils/strings'
import {getLang} from '@/utils/locale'
import style from './style'
import { theme } from '../../style'

import { API } from '@/constants'

const useStyles = createUseStyles(style)

const Nav = ({
  mainNav,
  dashboardNav,
  extraNav,
  socialNav,
  userNav,
}) => {
  const { headerHeight, headerRef, cookieRef } = useContext(Context)
  const classes = useStyles({ headerHeight })
  const $containerNav = useRef()
  const location = useLocation()
  const history = useHistory()

  /*------------------------------
  Redux Connect
  ------------------------------*/
  const {currentLanguage,languages,schema,pathname, isMenuOpen, strings, isLoggedIn, profile, fakeLocation, release } = useSelector((state) => ({
    currentLanguage: state.locale.currentLanguage || getLang(navigator.language),
    languages: state.locale.languages,
    schema: state.locale.schema,
    pathname: state.router.location.pathname,
    strings: state.options.strings,
    isLoggedIn: state.user.isLoggedIn,
    profile: state.user.profile,
    fakeLocation: state.fakerouter.location,
    release: state.fakerouter.release,
    isMenuOpen: state.layer.layers.some((layer) => layer.id === 'menu' && layer.isOpen),
  }), shallowEqual)
  const prevPathname = usePrevious(pathname)
  const [fieldValue, setFieldValue] = useState('')
  useEffect(()=>{
    if(Object.keys(schema).length>0 && schema[currentLanguage]!==undefined){
      setFieldValue(schema[currentLanguage].title)
    }
  },[schema,currentLanguage])
  /*------------------------------
  Redux Actions
  ------------------------------*/
  const dispatch = useDispatch()
  const closeMenu = useCallback(() => dispatch(layerActions.closeMenu()), [dispatch])
  const fetchLogout = useCallback(() => dispatch(userActions.fetchLogout()), [dispatch])
  const resetExperience = useCallback(() => dispatch(experienceActions.resetExperience()), [dispatch])
  const setLanguage = useCallback((code) => dispatch(localeActions.setLanguage(code)), [dispatch])

  const $root = useRef()
  const disableAllTabIndex = useRef(null)

  const [ready, setReady] = useState(false)

  useEffect(() => {
    if (mainNav?.length) setReady(true)
  }, [mainNav])

  /*------------------------------
  Initialize
  ------------------------------*/
  const init = () => {
    gsap.set($root.current, { autoAlpha: 0, x: 100 })
    gsap.set($containerNav.current, { autoAlpha: 0, x: 50 })
  }

  const handleKeyDown = (e) => {
    if (e.key === 'Escape' && e.keyCode === 27 && isMenuOpen) closeMenu()
  }

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown)
    if (theme.detect.hasTouch) {
      document.body.style.overflowY = isMenuOpen ? 'hidden' : 'visible'
    }
    return () => window.removeEventListener('keydown', handleKeyDown)
  }, [isMenuOpen])

  const openMainMenu = () => {
    gsap.killTweensOf([$root.current, $containerNav.current])
    const tl = gsap.timeline({ ease: 'power3.inOut' })
    tl
      .to($root.current, {
        duration: 0.5,
        x: 0,
        autoAlpha: 1,
      })
      .to([$containerNav.current], {
        duration: 0.5,
        autoAlpha: 1,
        x: 0,
        stagger: 0.08,
      }, '<0.2')
  }

  const closeMainMenu = () => {
    gsap.killTweensOf([$root.current, $containerNav.current])
    gsap.to($root.current, {
      duration: 0.5,
      autoAlpha: 0,
      x: 100,
    })
    gsap.to($containerNav.current, {
      duration: 0.5,
      autoAlpha: 0,
      x: 50,
    })
  }

  /*------------------------------
  Init
  ------------------------------*/
  useEffect(() => {
    if (ready) init()
  }, [ready])

  /*------------------------------
  Manage Accessibility
  ------------------------------*/
  useEffect(() => {
    if (isMenuOpen) {
      disableAllTabIndex.current = ally.maintain.disabled({
        filter: [$root.current, headerRef.current, ...cookieRef.current ? [cookieRef.current] : []],
      })
    }
    if (!isMenuOpen && disableAllTabIndex.current !== null) disableAllTabIndex.current.disengage()
  }, [isMenuOpen])

  /*------------------------------
  Close Nav when isMenuOpen changed
  ------------------------------*/
  const prevIsOpen = usePrevious(isMenuOpen)
  useEffect(() => {
    if (prevIsOpen !== undefined && isMenuOpen) openMainMenu()
    if (prevIsOpen !== undefined && !isMenuOpen) closeMainMenu()
  }, [isMenuOpen])

  /*------------------------------
  Close Nav on change page
  ------------------------------*/
  useLayoutEffect(() => {
    if ((prevPathname !== pathname) && isMenuOpen) {
      setTimeout(() => {
        closeMenu()
      }, 500)
    }
  }, [pathname, isMenuOpen])

  /*------------------------------
  Close Nav on change fakeLocation
  ------------------------------*/
  const prevFakeLocation = usePrevious(fakeLocation)
  useEffect(() => {
    if ((prevFakeLocation !== fakeLocation) && isMenuOpen) {
      closeMenu()
    }
  }, [fakeLocation, isMenuOpen])

  const handleStartNewExperience = () => {
    /*if (pathname === '/' && fakeLocation === 'begin') {
      closeMenu()
    } else {
      history.push('/')
      resetExperience()
    }*/

    history.push('/');
    resetExperience();
    closeMenu();
  }

  const handleLogin = () => {
    closeMenu()
    window.location.replace(API.OAUTH_URL);
  }

  const handleLogout = () => {
    closeMenu() 
    history.push('/logout');
  }
  
  const renderNav = (nav, type) => {
    return (
      <nav
        className={classNames({
          [classes.nav]: true,
          [classes.mainNav]: type === 'main',
          [classes.extraNav]: type === 'extra',
        })}
      >
        <ul>
          {nav.map((item, i) => (
            <li key={i.toString()}>
              <DelayLink
                className={classNames({
                  [classes.active]: getSlug(item.link) === getSlug(location.pathname),
                })}
                to={item.link}
              >
                {decodeEntities(item.title)}
              </DelayLink>
            </li>
          ))}
        </ul>
      </nav>
    )
  }

  const renderLoggedHeader = () => {
    const { nickname } = profile
    return (
      <div className={classes.loggedHeader}>
        <DelayLink to="/portal" className={classes.avatar}>
          {nickname.charAt(0).toUpperCase()}
        </DelayLink>
        <div className={classes.info}>
          <div className={classes.username}>{nickname}</div>
          <DelayLink to="/logout" className={classes.userLink} delay={2000}>
            {strings['form.logout']}
          </DelayLink>
        </div>
        <LanguageSelectField
          label={"Choose Language"}
          name="chooseLanguage"
          value={fieldValue}
          options={languages.map((lang) => ({ id: lang.code, name: schema[lang.code].title }))}
          setFieldValue={(label, value) => {
            setLanguage(value)
            setFieldValue(label)
          }}
        />
      </div>
    )
  }

  const renderNotLoggedHeader = () => {
    return (
      <div className={classes.notLoggedHeader}>
        <div>
          {dashboardNav.map((item, i) => (
            <Fragment key={i.toString()}>
              <button className={classes.userLink} onClick={handleLogin}>
                {item.title}
              </button>
              {i !== dashboardNav.length - 1 ? '/' : ''}
            </Fragment>
          ))}
        </div>
        <LanguageSelectField
          label={"Choose Language"}
          name="chooseLanguage"
          value={fieldValue}
          options={languages.map((lang) => ({ id: lang.code, name: schema[lang.code].title }))}
          setFieldValue={(label, value) => {
            console.log(label,value)
            setLanguage(value)
            setFieldValue(label)
          }}
        />
      </div>
    )
  }

  return ready && (
    <div
      className={classNames({
        [classes.root]: true,
        [classes.rootOpened]: isMenuOpen,
      })}
      ref={$root}
      role="dialog"
      aria-modal="true"
    >
      <div className={classes.header}>
        { isLoggedIn ? renderLoggedHeader() : renderNotLoggedHeader() }
      </div>
      <div
        className={classes.container}
        ref={$containerNav}
      >
        <div data-main-nav>
          {/*isLoggedIn &&*/ renderNav(userNav, 'main') }
          {renderNav(mainNav, 'main')}
          <nav
            className={classNames({
              [classes.nav]: true,
              [classes.mainNav]: true,
            })}
          >
            <ul><li><button onClick={handleStartNewExperience}>{strings['results.restart']}</button></li></ul>
          </nav>
        </div>
        <div data-extra-nav>
          {renderNav(extraNav, 'extra')}
        </div>
      </div>
      <div className={classes.footer}>
        <div className={classes.footerSocial}>
          <ul>
            {socialNav.map((item, i) => (
              <li key={i.toString()}>
                <DelayLink to={item.link}>
                  <img src={`/frontend/static/images/social/${item.title.toLowerCase()}.svg`} alt={item.title} />
                </DelayLink>
              </li>
            ))}
          </ul>
        </div>
        <ParseText
          className={classes.footerText}
          text={convertRichText(strings['footer.text'])}
        />
      </div>
    </div>
  )
}

export default Nav
