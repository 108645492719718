import { useEffect, useRef, useState, useCallback } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { createUseStyles } from "react-jss";
import gsap from "gsap";
import useStore from "@/base/zustand";
import RevealText from "@/components/RevealText";
import RevealFadeIn from "@/components/RevealFadeIn";
import { useResize } from "@/components/Handlers";
import usePrevious from "@/hooks/usePrevious";
import * as layerActions from "@/actions/layer";
import * as contentActions from "@/actions/content";
import { convertRichText } from "@/utils/strings";
import style from "./style";

const useStyles = createUseStyles(style);

const FaceScanModal = () => {
  const $root = useRef();
  const rootBounds = useRef();
  const [entered, setEntered] = useState(false);
  const [modalHeight, setModalHeight] = useState(0);
  const classes = useStyles({ modalHeight });
  const { setModalClosed, setInitFaceScan } = useStore();
  const [radiusLoading, setRadiusLoading] = useState(window.innerWidth * 0.5);

  /*------------------------------
  Redux Connect
  ------------------------------*/
  const { page, isLayerOpen,currentLanguage } = useSelector(
    (state) => ({
      page: state.content["facial-scan-popup"] || {},
      isLayerOpen: state.layer.layers.some(
        (layer) => layer.id === "face-scan-modal" && layer.isOpen
      ),
      currentLanguage: state.locale.currentLanguage,
    }),
    shallowEqual
  );

  /*------------------------------
  Redux Actions
  ------------------------------*/
  const dispatch = useDispatch();
  const closeLayer = useCallback(() => {
    dispatch(layerActions.closeLayer({ id: "face-scan-modal" }));
    setModalClosed(true);
  }, [dispatch]);
  const fetchContent = useCallback(
    (slug) => dispatch(contentActions.fetchContent(slug,'popup')),
    [dispatch]
  );

  /*------------------------------
  Fetch Content
  ------------------------------*/
  useEffect(() => {
    if (Object.keys(page).length === 0) fetchContent("facial-scan-popup");
  }, []);

  useEffect(() => {
    if (Object.keys(page).length > 0 )
      fetchContent('facial-scan-popup');
  }, [currentLanguage])

  /*------------------------------
  Calculate Radius responsive
  ------------------------------*/
  useResize(() => {
    const aspect = window.innerWidth / window.innerHeight;
    setRadiusLoading(
      aspect > 0.7 ? window.innerHeight * 0.65 : window.innerWidth * 0.9
    );
  });

  /*------------------------------
  Get Header Height
  ------------------------------*/
  useResize(() => {
    if ($root.current) {
      rootBounds.current = $root.current.getBoundingClientRect();
      setModalHeight($root.current.getBoundingClientRect().height);
    }
  });
  useEffect(() => {
    setTimeout(() => {
      rootBounds.current = $root.current.getBoundingClientRect();
      setModalHeight($root.current.getBoundingClientRect().height);
    }, 1000);
  }, []);

  /*------------------------------
  Handle Keydown
  ------------------------------*/
  // const handleKeyDown = (e) => {
  //   if (e.key === 'Escape' && e.keyCode === 27 && isLayerOpen){closeLayer()}
  // }
  // useEffect(() => {
  //   window.addEventListener('keydown', handleKeyDown)
  //   return () => window.removeEventListener('keydown', handleKeyDown)
  // }, [isLayerOpen])

  /*------------------------------
  Open Modal Animation
  ------------------------------*/
  const openModal = () => {
    gsap.killTweensOf([$root.current]);
    const tl = gsap.timeline({
      ease: "power3.inOut",
      onComplete: () => setEntered(true),
    });
    tl.to($root.current, {
      duration: 0.5,
      y: 0,
      autoAlpha: 1,
    });
  };

  /*------------------------------
  Close Modal Animation
  ------------------------------*/
  const closeModal = () => {
    setEntered(false);
    gsap.killTweensOf([$root.current]);
    gsap.to($root.current, {
      duration: 0.5,
      y: 50,
      autoAlpha: 0,
    });
  };

  /*------------------------------
  Close Layer when isLayerOpen changed
  ------------------------------*/
  const prevIsOpen = usePrevious(isLayerOpen);
  useEffect(() => {
    if (prevIsOpen !== undefined && isLayerOpen) openModal();
    if (prevIsOpen !== undefined && !isLayerOpen) closeModal();
  }, [isLayerOpen]);

  return (
    <>
{   isLayerOpen && <img src="/frontend/static/images/general/face-scan-background.png" style={{width:radiusLoading,height:radiusLoading,position:'fixed',top:'50%',left:'50%', transform:'translate(-50%, -50%)'}} alt="" />
}      <div className={classes.root} ref={$root} role="dialog" aria-modal="true">
        <div className={classes.box}>
          <RevealText
            tag="h1"
            type="lines"
            value={page.popUpHeading}
            visible={entered}
            lineHeight={1.4}
            delay={0}
            className={classes.title}
          />
          <RevealText
            tag="div"
            type="lines"
            value={convertRichText(page.popUpCopy)}
            visible={entered}
            lineHeight={1.333}
            delay={0}
            className={classes.text}
          />
          <RevealFadeIn delay={0} inview={entered}>
            <button
              onClick={() => {
                closeLayer();
              }}
              className={classes.btn}
            >
              {page.popUpButton}
            </button>
          </RevealFadeIn>
        </div>
      </div>
    </>
  );
};

export default FaceScanModal;
