/* global CY */
import { useEffect, useRef, useContext, useCallback, useState } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { createUseStyles, useTheme } from "react-jss";
import gsap from "gsap";
import useSound from "use-sound";
import classnames from "classnames";
import { Context as GeneralContext } from "@/context";
import { Context as AudioContext } from "@/context/audio";
import useStore from "@/base/zustand";
import { useResize } from "@/components/Handlers";
import * as routerActions from "@/actions/fakerouter";
import * as experienceActions from "@/actions/experience";
import * as emotionsActions from "@/actions/emotions";
import * as contentActions from "@/actions/content";
import { avarage, randomLow, scanValue } from "@/utils/math";
import { getImageData } from "@/utils/dom";
import { MORPHCAST_API_KEY } from "@/constants";
import fxAudioStep from "@/assets/audio/audio_fx_step.mp3";
import fxAudioComplete from "@/assets/audio/audio_fx_complete.mp3";
import React from 'react';
import style from "./style";

const useStyles = createUseStyles(style);

const SCAN_TIME = 10;

const FaceEmotions = ({ ready, faceFilterRef, first }) => {
  const {
    initFaceScan,
    setInitFaceScan,
    isFaceDetected,
    isFaceScanFinish,
    setStartFaceScan,
    isStartFaceScan,
    setIsFaceScanFinish,
    isCameraEnabled,
    isGettingEmotions,
    scanStatus,
    setScanStatus,
    isFaceInCamera,
    setTimerStarted,
    isTimerStarted,
    isModalClosed,
    setModalClosed
  } = useStore();
  const $frame = useRef();
  const $loading = useRef();
  const theme = useTheme();
  const { headerHeight } = useContext(GeneralContext);
  const { isAudioActive } = useContext(AudioContext);
  const classes = useStyles({ headerHeight });
  const maxSpikes = useRef(200);
  const currentSpike = useRef({ value: -1 });
  const morphcast = useRef();
  const $root = useRef();
  const emotionsValue = useRef();
  const CYCustomSource = useRef();
  const [radiusLoading, setRadiusLoading] = useState(window.innerWidth * 0.5);
  const [emotionCounter, setEmotionCounter] = useState(0);
  const status = useRef({ value: 0 });
  const [playbackRate, setPlaybackRate] = useState(0.75);
  const [playFxStep] = useSound(fxAudioStep, { playbackRate });
  const [playFxComplete] = useSound(fxAudioComplete);
  const timelineTimer = useRef();
  const timelineStatus = useRef();
  const [reload, setReload] = useState(false);
  const intervalRef = useRef();
  const [faceScanEmotions, setFaceScanEmotions] = useState([]);

  /*------------------------------
  Animate on Ready
  ------------------------------*/
  useEffect(() => {
    if (ready && isCameraEnabled) {
      gsap.to($root.current, {
        startAt: {
          opacity: 0,
        },
        opacity: 1,
        duration: 0,
      });
    }
  }, [ready, isCameraEnabled]);

  /*------------------------------
  Redux Store
  ------------------------------*/
  const { page,emotions, strings, step, userImage, isLoggedIn, profile,currentLanguage } =
    useSelector(
      (state) => ({
        page: state.content['face-scan'] || {},
        strings: state.options.strings,
        step: state.experience.step,
        userImage: state.experience.user_image,
        emotions: state.emotions.items,
        isLoggedIn: state.user.isLoggedIn,
        profile: state.user.profile,
        currentLanguage:state.locale.currentLanguage
        // age: state.experience.age,
        // gender: state.experience.gender,
      }),
      shallowEqual
    );

  /*------------------------------
  Redux Actions
  ------------------------------*/
  const dispatch = useDispatch();
  const moveForward = useCallback(
    () => dispatch(routerActions.moveForward()),
    [dispatch]
  );
  const moveNext = useCallback(
    (name) => dispatch(routerActions.changeLocation(name)),
    [dispatch]
  );
  const setEmotion = useCallback(
    (value, key, s) =>
      dispatch(experienceActions.setSingleEmotion(value, key, s)),
    [dispatch]
  );
  // const setAge = useCallback((number) => dispatch(experienceActions.setAge(number)), [dispatch])
  // const setGender = useCallback((number) => dispatch(experienceActions.setGender(number)), [dispatch])
  const setUserImage = useCallback(
    (value) => dispatch(experienceActions.setUserImage(value)),
    [dispatch]
  );
  const fetchEmotions = useCallback(
    () => dispatch(emotionsActions.fetchEmotions()),
    [dispatch]
  );
  const setExperienceGender = useCallback(
    (number) => dispatch(experienceActions.setGender(number)),
    [dispatch]
  );
  const setExperienceAge = useCallback(
    (number) => dispatch(experienceActions.setAge(number)),
    [dispatch]
  );
  const fetchContent = useCallback((slug) => dispatch(contentActions.fetchContent(slug,'faceScan')), [dispatch])

  /*------------------------------
  Fetch Content
  ------------------------------*/
  useEffect(() => {
    if (emotions.length === 0) fetchEmotions();
    if (Object.keys(page).length === 0) fetchContent('face-scan')

    randomCircleInterval();
    return () => {
      setModalClosed(false);
    };
  }, []);
  useEffect(() => {
    if (Object.keys(page).length > 0 )
      fetchContent('face-scan');
  }, [currentLanguage])
  const shuffle = (array) => {
    let currentIndex = array.length,
      randomIndex;

    // While there remain elements to shuffle...
    while (currentIndex != 0) {
      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;

      // And swap it with the current element.
      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex],
        array[currentIndex],
      ];
    }

    return array;
  };

  /*------------------------------
  Emotions Presetting
  ------------------------------*/
  useEffect(() => {
    if (emotions.length > 0) {
      emotionsValue.current = emotions
        .filter((e) => e.step === "facescan")
        .reduce((obj, item) => {
          return {
            ...obj,
            [item.key]: [],
          };
        }, {});

      setFaceScanEmotions(
        shuffle(emotions).filter((e) => e.step === "facescan")
      );
    }
  }, [emotions]);

  /*------------------------------
  Get Emotions Callback
  ------------------------------*/
  const getEmotions = ({ detail }) => {
    const emotion = detail.output.affects38;

    // window.console.log('emotion ---->', detail.output.affects38)

    emotionsValue.current.positive.push(
      emotion.Excited > 0 ? scanValue(emotion.Excited, 1.5) : randomLow()
    );
    emotionsValue.current.content.push(
      emotion.Satisfied > 0 ? scanValue(emotion.Satisfied, 1.5) : randomLow()
    );
    emotionsValue.current.relaxed.push(
      emotion.Relaxed > 0 ? scanValue(emotion.Relaxed, 1.5) : randomLow()
    );
    emotionsValue.current.focused.push(
      emotion.Determined > 0 ? scanValue(emotion.Determined, 1.5) : randomLow()
    );
    setEmotionCounter((prev) => (prev += 1));
  };

  /*------------------------------
  Set User Images
  ------------------------------*/
  useEffect(() => {
    if (faceFilterRef.current && isTimerStarted) {
      intervalRef.current = setInterval(() => {
        const img = faceFilterRef.current.toDataURL("image/jpeg", 0.2);
        setUserImage(img);
      }, 200);
    }
  }, [isTimerStarted]);

  useEffect(() => {
    // if ((isFaceScanFinish || emotionCounter > 4) && gender > 0 && age > 0) {
    if (isFaceScanFinish || emotionCounter > 4) {
      clearInterval(intervalRef.current);
    }
  }, [isFaceScanFinish, emotionCounter]);

  /*------------------------------
  Get Age Callback
  ------------------------------*/
  // const getAge = ({ detail }) => {
  //   // window.console.log('age ---->', detail.output.numericAge)
  //   setAge(detail.output.numericAge)
  // }

  /*------------------------------
  Get Gender Callback
  ------------------------------*/
  // const getGender = ({ detail }) => {
  //   // window.console.log('gender ---->', detail.output.mostConfident)
  //   let numericGender = 0
  //   if (detail.output.mostConfident === 'Male') {
  //     numericGender = 1
  //   } else if (detail.output.mostConfident === 'Female') {
  //     numericGender = 2
  //   }
  //   setGender(numericGender)
  // }

  /*------------------------------
  Handle Click Continue & push Values when scan is finished
  ------------------------------*/
  const handleClickContinue = () => {
    // window.console.log('emotionsValue ---->', emotionsValue.current)
    setEmotion(
      avarage(emotionsValue.current.positive),
      "positive",
      step === 1 ? "pre" : "post"
    );
    setEmotion(
      avarage(emotionsValue.current.content),
      "content",
      step === 1 ? "pre" : "post"
    );
    setEmotion(
      avarage(emotionsValue.current.relaxed),
      "relaxed",
      step === 1 ? "pre" : "post"
    );
    setEmotion(
      avarage(emotionsValue.current.focused),
      "focused",
      step === 1 ? "pre" : "post"
    );
    if (first) {
      if (isLoggedIn && profile.gender !== null && profile.age !== null) {
        setExperienceGender(profile.gender);
        setExperienceAge(profile.age);
        moveNext("questions-1");
      } else {
        moveNext("choose-age-gender");
      }
    } else {
      moveForward();
    }
    setUserImage("");
  };

  /*------------------------------
  Handle Click Restart Scan
  ------------------------------*/
  const handleClickRestartScan = () => {
    setIsFaceScanFinish(true);

    setTimeout(() => {
      setIsFaceScanFinish(false);
      setStartFaceScan(false);
      setTimerStarted(false);
      setPlaybackRate(0.75);
      currentSpike.current.value = -1;
      status.current.value = 0;
      setInitFaceScan(false);
      timelineTimer.current.kill();
      timelineStatus.current.kill();
      setReload(true);
      if (morphcast.current) morphcast.current.start();
      randomCircleInterval();
    }, 100);

    setTimeout(() => {
      setInitFaceScan(true);
      setReload(false);
    }, 2000);
  };

  /*------------------------------
  Morphcast Initialize & Destroy
  ------------------------------*/
  useEffect(() => {
    if (isCameraEnabled === "enabled" && isGettingEmotions) {
      let crtImgData;
      let resolver;

      const customSource = {
        analyzeFrame(imageData) {
          if (resolver) {
            resolver(imageData);
            resolver = null;
          } else {
            crtImgData = imageData;
          }
        },
        getFrame() {
          if (crtImgData) {
            const p = Promise.resolve(crtImgData);
            crtImgData = null;
            return p;
          }
          return new Promise((res) => (resolver = res));
        },
        start() {},
        stop() {},
      };

      // const customSource = CY.createSource.fromVideoElement(videoEl)

      CY.loader()
        .licenseKey(MORPHCAST_API_KEY)
        .addModule(CY.modules().FACE_AROUSAL_VALENCE.name)
        // .addModule(CY.modules().FACE_AGE.name)
        // .addModule(CY.modules().FACE_GENDER.name)
        .source(customSource)
        .load()
        .then((e) => {
          morphcast.current = e;
          e.start();
        });

      // Listen emotion event
      window.addEventListener(
        CY.modules().FACE_AROUSAL_VALENCE.eventName,
        getEmotions
      );
      // window.addEventListener(CY.modules().FACE_AGE.eventName, getAge)
      // window.addEventListener(CY.modules().FACE_GENDER.eventName, getGender)
      // Get Image from camera stream
      CYCustomSource.current = customSource;
    }

    // return () => {
    //   if (isCameraEnabled === 'enabled' && morphcast.current && isGettingEmotions) {
    //     morphcast.current.terminate()
    //     window.removeEventListener(CY.modules().FACE_AROUSAL_VALENCE.eventName, getEmotions)
    //     window.removeEventListener(CY.modules().FACE_AGE.eventName, getAge)
    //     window.removeEventListener(CY.modules().FACE_GENDER.eventName, getGender)
    //   }
    // }
  }, [isCameraEnabled, isGettingEmotions]);

  useEffect(() => {
    if (isFaceScanFinish) {
      if (morphcast.current) morphcast.current.stop();
      window.removeEventListener(
        CY.modules().FACE_AROUSAL_VALENCE.eventName,
        getEmotions
      );
      // window.removeEventListener(CY.modules().FACE_AGE.eventName, getAge)
      // window.removeEventListener(CY.modules().FACE_GENDER.eventName, getGender)
    }
  }, [isFaceScanFinish]);

  /*------------------------------
  Analyze Image
  ------------------------------*/
  useEffect(() => {
    if (userImage !== "" && CYCustomSource.current) {
      // window.console.log('userImage ---->', userImage)
      getImageData(userImage).then((imgData) =>
        CYCustomSource.current.analyzeFrame(imgData)
      );
    }
  }, [userImage]);

  /*------------------------------
  Calculate Radius responsive
  ------------------------------*/
  useResize(() => {
    const aspect = window.innerWidth / window.innerHeight;
    setRadiusLoading(
      aspect > 0.7 ? window.innerHeight * 0.65 : window.innerWidth * 0.9
    );
  });

  /*------------------------------
  Init Scan Timer
  ------------------------------*/
  useEffect(() => {
    if (ready && !reload) {
      timelineTimer.current = gsap.timeline({ paused: true });
      timelineTimer.current.to(currentSpike.current, {
        value: maxSpikes.current,
        duration: SCAN_TIME,
        ease: "linear",
        onComplete: () => setIsFaceScanFinish(true),
        onStart: () => setTimerStarted(true),
      });

      timelineStatus.current = gsap.timeline({ paused: true });
      faceScanEmotions.map((emotion, index) => {
        timelineStatus.current.to(status.current, {
          value: 10,
          duration: SCAN_TIME / 4,
          ease: "linear",
          onComplete: () => {
            if (index < faceScanEmotions.length - 1) setScanStatus(index + 1);
          },
        });
        return null;
      });
    }
  }, [ready, reload]);

  /*------------------------------
  Play & Pause Timer
  ------------------------------*/
  useEffect(() => {
    if (
      isStartFaceScan &&
      isFaceInCamera &&
      timelineTimer.current &&
      timelineStatus.current
    ) {
      timelineTimer.current.play();
      timelineStatus.current.play();
    }
    if (
      isStartFaceScan &&
      !isFaceInCamera &&
      timelineTimer.current &&
      timelineStatus.current
    ) {
      timelineTimer.current.pause();
      timelineStatus.current.pause();
    }
  }, [isFaceInCamera, isStartFaceScan]);

  /*------------------------------
  Trigger Audio FX on step
  ------------------------------*/
  useEffect(() => {
    if (scanStatus > 0 && isAudioActive) {
      setPlaybackRate((prevState) => prevState + 0.1);
      playFxStep();
    }
  }, [scanStatus, isAudioActive]);

  /*------------------------------
  Trigger Audio FX on complete
  ------------------------------*/
  useEffect(() => {
    if (isFaceScanFinish && isAudioActive) playFxComplete();
  }, [isFaceScanFinish, isAudioActive]);

  const randomCircleInterval = () => {
    var circleElement = document.getElementById("randomCircle");
    const bounds = circleElement.getBoundingClientRect();

    if (undefined !== randomAnimationInterval) {
      clearInterval(randomAnimationInterval);
    }

    let randomAnimationInterval = setInterval(() => {
      let random = Math.random() * 100;
      let random2 = Math.random() * 100;
      circleElement.style.left = "calc(" + random + "% - 75px)";
      circleElement.style.top = "calc(" + random2 + "% - 75px)";
    }, 3000);
  };

  /*------------------------------
  Create Random Circles
  ------------------------------*/
  const createRandomCircles = () => {
    if (
      (isTimerStarted && !isFaceInCamera) ||
      (isStartFaceScan && !isFaceInCamera) ||
      (emotionCounter === 0 && isFaceScanFinish) ||
      isFaceScanFinish
    ) {
      return null;
    } else {
      return (
        <div className={classes.randomCircleWrapper}>
          <div
            className={`${classes.circles} ${classes.circleAnimation}`}
            id="randomCircle"
          >
            {isModalClosed && (
              <>
                <div className={classes.circle} />
                <div className={classes.circle} />
                <div className={classes.circle} />
              </>
            )}
          </div>
        </div>
      );
    }
  };

  /*------------------------------
  Create Spikes
  ------------------------------*/
  const createSpikes = () => {
    return Array(maxSpikes.current)
      .fill()
      .map((a, i) => (
        <div
          key={i.toString()}
          className={classnames({
            [classes.spike]: true,
            [classes.spikeFinish]: isFaceScanFinish,
            active: i <= currentSpike.current.value,
          })}
          style={{
            transform: `rotate(${(i / maxSpikes.current) * 360}deg)`,
            "--color": faceScanEmotions?.[scanStatus]?.color || theme.colors[3],
          }}
        />
      ));
  };

  return (
    <div ref={$root} className={classes.emotionsRoot}>
      <div
        className={classnames({
          [classes.loading]: true,
        })}
        ref={$loading}
        style={{ "--radius": `${radiusLoading}px` }}
      >
        {createRandomCircles()}
        {createSpikes()}
      </div>
      <div
        ref={$frame}
        className={classes.frame}
        style={{ "--radius": `${radiusLoading}px`, "--color": "#ffbe8d" }}
      >
        <div className={classes.topleft} />
        <div className={classes.topright} />
        <div className={classes.bottomleft} />
        <div className={classes.bottomright} />
        <span
          className={classnames({
            [classes.error]: true,
            visible:
              (isTimerStarted && !isFaceInCamera) ||
              (isStartFaceScan && !isFaceInCamera),
          })}
        >
          {strings["scan.face.notrecognized"]}
        </span>
      </div>
      <div
        className={classnames({
          [classes.text]: true,
          [classes.visible]: !isFaceScanFinish,
          [classes.notVisible]:
            isFaceScanFinish ||
            (isTimerStarted && !isFaceInCamera) ||
            (isStartFaceScan && !isFaceInCamera),
        })}
      >
        <div
          className={classnames({
            [classes.traitList]: true,
            [classes.notVisible]: isFaceScanFinish,
          })}
        >
          {faceScanEmotions?.map((emotion, i) => (
            <div
              key={i.toString()}
              className={classnames({
                [classes.trait]: true,
                [classes.traitActive]:
                  i === scanStatus && isFaceDetected && isTimerStarted,
              })}
              style={{ "--color": emotion.color }}
            >
              {emotion.name}
            </div>
          ))}
        </div>
        <div dangerouslySetInnerHTML={{ __html: page.faceScanInstruction1 }} />
        {isModalClosed && <strong>{page.faceScanning}</strong>}{" "}
      </div>
      <div
        className={classnames({
          [classes.text]: true,
          [classes.visible]: isFaceScanFinish,
        })}
        style={{ bottom: 120 }}
      >
        {emotionCounter > 0 && isFaceScanFinish ? (
          <div
            className={classnames({
              [classes.trait]: true,
              [classes.traitActive]: true,
              [classes.visible]: emotionCounter > 0 && isFaceScanFinish,
            })}
            style={{ "--color": "#485ac7" }}
          >
            {page.completed}
          </div>
        ) : null}
      </div>
      <button
        className={classnames({
          [classes.btn]: true,
          [classes.continueBtn]: true,
          // [classes.visible]: emotionCounter > 0 && isFaceScanFinish && gender > 0 && age > 0,
          [classes.visible]: emotionCounter > 0 && isFaceScanFinish,
        })}
        style={{ transitionDelay: "1s" }}
        onClick={handleClickContinue}
      >
        <span>{page.continue}</span>
      </button>
      <div
        className={classnames({
          [classes.restartScan]: true,
          [classes.visible]:
            (isTimerStarted && !isFaceInCamera) ||
            (isStartFaceScan && !isFaceInCamera) ||
            (emotionCounter === 0 && isFaceScanFinish),
          // || (isFaceScanFinish && (gender === 0 || age === 0)),
        })}
      >
        <span>{page.restartDescription}</span>
        <button className={classes.btn} onClick={handleClickRestartScan}>
          <span>{page.restartButton}</span>
        </button>
      </div>
    </div>
  );
};

export default React.memo(FaceEmotions);
